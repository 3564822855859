module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../mdrt-theme-recruitment/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Premier Association of Financial Professionals®","short_name":"Join MDRT","start_url":"/","background_color":"#3d6baa","theme_color":"#3d6baa","display":"standalone","icon":"src/assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12953235f965235997767d56915d05f0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WLGNKC6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"cookiePolicyInOtherWindow":true,"consentOnDocument":true,"enableRemoteConsent":true,"lang":"en","siteId":"1672073","cookiePolicyId":"59167206","cookiePolicyUrl":"https://www.mdrt.org/privacy","banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-top-center","acceptButtonColor":"#3d6baa","customizeButtonCaptionColor":"#494949","textColor":"#494949","backgroundColor":"#f7f9ff","fontSize":"14px","customizeButtonCaption":"Learn more","cookiePolicyLinkCaption":"Cookie Policy"},"googleTagManagerOptions":{"eventName":"iubenda_consent_given","dataLayerName":"dataLayer"}},"googleTagManagerOptions":true},
    }]
